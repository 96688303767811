/* static fonts */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../fonts/inter/Inter-Thin.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("../fonts/inter/Inter-ThinItalic.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../fonts/inter/Inter-ExtraLight.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("../fonts/inter/Inter-ExtraLightItalic.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/inter/Inter-Light.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/inter/Inter-LightItalic.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/inter/Inter-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/inter/Inter-Italic.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/inter/Inter-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/inter/Inter-MediumItalic.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/inter/Inter-SemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/inter/Inter-SemiBoldItalic.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/inter/Inter-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/inter/Inter-BoldItalic.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../fonts/inter/Inter-ExtraBold.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("../fonts/inter/Inter-ExtraBoldItalic.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../fonts/inter/Inter-Black.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("../fonts/inter/Inter-BlackItalic.woff2") format("woff2");
}

// @font-face {
//   font-family: "InterDisplay";
//   font-style: normal;
//   font-weight: 100;
//   font-display: swap;
//   src: url("../fonts/inter/InterDisplay-Thin.woff2") format("woff2");
// }
// @font-face {
//   font-family: "InterDisplay";
//   font-style: italic;
//   font-weight: 100;
//   font-display: swap;
//   src: url("../fonts/inter/InterDisplay-ThinItalic.woff2") format("woff2");
// }
// @font-face {
//   font-family: "InterDisplay";
//   font-style: normal;
//   font-weight: 200;
//   font-display: swap;
//   src: url("../fonts/inter/InterDisplay-ExtraLight.woff2") format("woff2");
// }
// @font-face {
//   font-family: "InterDisplay";
//   font-style: italic;
//   font-weight: 200;
//   font-display: swap;
//   src: url("../fonts/inter/InterDisplay-ExtraLightItalic.woff2") format("woff2");
// }
// @font-face {
//   font-family: "InterDisplay";
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: url("../fonts/inter/InterDisplay-Light.woff2") format("woff2");
// }
// @font-face {
//   font-family: "InterDisplay";
//   font-style: italic;
//   font-weight: 300;
//   font-display: swap;
//   src: url("../fonts/inter/InterDisplay-LightItalic.woff2") format("woff2");
// }
// @font-face {
//   font-family: "InterDisplay";
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url("../fonts/inter/InterDisplay-Regular.woff2") format("woff2");
// }
// @font-face {
//   font-family: "InterDisplay";
//   font-style: italic;
//   font-weight: 400;
//   font-display: swap;
//   src: url("../fonts/inter/InterDisplay-Italic.woff2") format("woff2");
// }
// @font-face {
//   font-family: "InterDisplay";
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: url("../fonts/inter/InterDisplay-Medium.woff2") format("woff2");
// }
// @font-face {
//   font-family: "InterDisplay";
//   font-style: italic;
//   font-weight: 500;
//   font-display: swap;
//   src: url("../fonts/inter/InterDisplay-MediumItalic.woff2") format("woff2");
// }
// @font-face {
//   font-family: "InterDisplay";
//   font-style: normal;
//   font-weight: 600;
//   font-display: swap;
//   src: url("../fonts/inter/InterDisplay-SemiBold.woff2") format("woff2");
// }
// @font-face {
//   font-family: "InterDisplay";
//   font-style: italic;
//   font-weight: 600;
//   font-display: swap;
//   src: url("../fonts/inter/InterDisplay-SemiBoldItalic.woff2") format("woff2");
// }
// @font-face {
//   font-family: "InterDisplay";
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: url("../fonts/inter/InterDisplay-Bold.woff2") format("woff2");
// }
// @font-face {
//   font-family: "InterDisplay";
//   font-style: italic;
//   font-weight: 700;
//   font-display: swap;
//   src: url("../fonts/inter/InterDisplay-BoldItalic.woff2") format("woff2");
// }
// @font-face {
//   font-family: "InterDisplay";
//   font-style: normal;
//   font-weight: 800;
//   font-display: swap;
//   src: url("../fonts/inter/InterDisplay-ExtraBold.woff2") format("woff2");
// }
// @font-face {
//   font-family: "InterDisplay";
//   font-style: italic;
//   font-weight: 800;
//   font-display: swap;
//   src: url("../fonts/inter/InterDisplay-ExtraBoldItalic.woff2") format("woff2");
// }
// @font-face {
//   font-family: "InterDisplay";
//   font-style: normal;
//   font-weight: 900;
//   font-display: swap;
//   src: url("../fonts/inter/InterDisplay-Black.woff2") format("woff2");
// }
// @font-face {
//   font-family: "InterDisplay";
//   font-style: italic;
//   font-weight: 900;
//   font-display: swap;
//   src: url("../fonts/inter/InterDisplay-BlackItalic.woff2") format("woff2");
// }