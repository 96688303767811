@font-face {
  font-family: 'Jost*';
  src: local('Jost* Thin'), local('Jost-Thin'),
      url('../fonts/jost/Jost-Thin.woff2') format('woff2'),
      url('../fonts/jost/Jost-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Hairline'), local('Jost-Hairline'),
      url('../fonts/jost/Jost-Hairline.woff2') format('woff2'),
      url('../fonts/jost/Jost-Hairline.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Hairline Italic'), local('Jost-HairlineItalic'),
      url('../fonts/jost/Jost-HairlineItalic.woff2') format('woff2'),
      url('../fonts/jost/Jost-HairlineItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Medium'), local('Jost-Medium'),
      url('../fonts/jost/Jost-Medium.woff2') format('woff2'),
      url('../fonts/jost/Jost-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Medium Italic'), local('Jost-MediumItalic'),
      url('../fonts/jost/Jost-MediumItalic.woff2') format('woff2'),
      url('../fonts/jost/Jost-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Light'), local('Jost-Light'),
      url('../fonts/jost/Jost-Light.woff2') format('woff2'),
      url('../fonts/jost/Jost-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Heavy Italic'), local('Jost-HeavyItalic'),
      url('../fonts/jost/Jost-HeavyItalic.woff2') format('woff2'),
      url('../fonts/jost/Jost-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Bold'), local('Jost-Bold'),
      url('../fonts/jost/Jost-Bold.woff2') format('woff2'),
      url('../fonts/jost/Jost-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Semi'), local('Jost-Semi'),
      url('../fonts/jost/Jost-Semi.woff2') format('woff2'),
      url('../fonts/jost/Jost-Semi.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Semi Italic'), local('Jost-SemiItalic'),
      url('../fonts/jost/Jost-SemiItalic.woff2') format('woff2'),
      url('../fonts/jost/Jost-SemiItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Thin Italic'), local('Jost-ThinItalic'),
      url('../fonts/jost/Jost-ThinItalic.woff2') format('woff2'),
      url('../fonts/jost/Jost-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Light Italic'), local('Jost-LightItalic'),
      url('../fonts/jost/Jost-LightItalic.woff2') format('woff2'),
      url('../fonts/jost/Jost-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Book'), local('Jost-Book'),
      url('../fonts/jost/Jost-Book.woff2') format('woff2'),
      url('../fonts/jost/Jost-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Black'), local('Jost-Black'),
      url('../fonts/jost/Jost-Black.woff2') format('woff2'),
      url('../fonts/jost/Jost-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Book Italic'), local('Jost-BookItalic'),
      url('../fonts/jost/Jost-BookItalic.woff2') format('woff2'),
      url('../fonts/jost/Jost-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Heavy'), local('Jost-Heavy'),
      url('../fonts/jost/Jost-Heavy.woff2') format('woff2'),
      url('../fonts/jost/Jost-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Bold Italic'), local('Jost-BoldItalic'),
      url('../fonts/jost/Jost-BoldItalic.woff2') format('woff2'),
      url('../fonts/jost/Jost-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Black Italic'), local('Jost-BlackItalic'),
      url('../fonts/jost/Jost-BlackItalic.woff2') format('woff2'),
      url('../fonts/jost/Jost-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}